import {
  format,
  isSameYear,
  fromUnixTime,
  formatDistanceToNow,
} from 'date-fns';

export default {
  methods: {
    messageStamp(time, dateFormat = 'h:mm a') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },
    messageTimestamp(time, dateFormat = 'MMM d, yyyy') {
      const messageTime = fromUnixTime(time);
      const now = new Date();
      const messageDate = format(messageTime, dateFormat);
      if (!isSameYear(messageTime, now)) {
        return format(messageTime, 'LLL d y, h:mm a');
      }
      return messageDate;
    },
    dynamicTime(time) {
      const unixTime = fromUnixTime(time);
      return formatDistanceToNow(unixTime, { addSuffix: true }).replace(/about|over|almost|/g, '')
      .replace('less than a minute ago', 'کمتر از چند دقیقه')
      .replace(' minute ago', ' دقیقه قبل')
      .replace(' minutes ago', ' دقیقه قبل')
      .replace('a minute ago', 'حدود یک دقیقه قبل')
      .replace('an hour ago', 'حدود یک ساعت قبل')
      .replace(' hour ago', ' ساعت قبل')
      .replace(' hours ago', ' ساعت قبل')
      .replace(' day ago', ' روز قبل')
      .replace('a day ago', 'حدود یک روز قبل')
      .replace(' days ago', ' روز قبل')
      .replace('a month ago', 'حدود یک ماه قبل')
      .replace(' months ago', ' ماه قبل')
      .replace(' month ago', ' ماه قبل')
      .replace('a year ago', 'حدود یک سال قبل')
      .replace(' year ago', ' سال قبل')
      .replace(' years ago', ' سال قبل');
    },
    dateFormat(time, dateFormat = 'MMM d, yyyy') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },
    shortTimestamp(time) {
      const convertToShortTime = time
      .replace('کمتر از چند دقیقه', 'تازگی')
      .replace(' دقیقه قبل', 'دقیقه')
      .replace(' دقیقه قبل', 'دقیقه')
      .replace('حدود یک دقیقه قبل', 'دقیقه')
      .replace('حدود یک ساعت قبل', 'ساعت')
      .replace(' ساعت قبل', 'ساعت')
      .replace(' ساعت قبل', 'ساعت')
      .replace(' روز قبل', 'روز')
      .replace('حدود یک روز قبل', 'روز')
      .replace(' روز قبل', 'روز')
      .replace('حدود یک ماه قبل', 'روز')
      .replace(' ماه قبل', 'ماه')
      .replace(' ماه قبل', 'ماه')
      .replace('حدود یک سال قبل', 'سال')
      .replace(' سال قبل', 'سال')
      .replace(' سال قبل', 'سال');
      return convertToShortTime;
    },
  },
};
